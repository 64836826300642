/**
 * Created by matthew on 18/10/19.
 */

jQuery(document).ready(function ($) {
	
	if ($('.cdcf_acf_dishes').length > 0) {
		
		acf.addAction('new_field/name=menu', function (field) {
			console.log('Load Field');
			gtekSetupACFMenus(field);
			
		});
		
		acf.addAction('load_field/name=menu', function (field) {
			
			gtekSetupACFMenus(field);
			
		});
		
		function gtekSetupACFMenus(field){
			
			var elems = field.$el;
			
			$.each(elems, function (index, elem) {
				
				var elem = $(elem),
				    menu = elem.find('option:checked').val(),
				    dishFieldKey = elem.parents('.layout').attr('data-id');
				console.log(dishFieldKey);
				gtekDishSelection(elem, menu, dishFieldKey);
				
				elem.on('change', function () {
					
					var menu = elem.find('option:checked').val();
					
					gtekDishSelection(elem, menu, dishFieldKey);
					
				})
				
			});
			
		}
		
		function gtekDishSelection(elem, menu, fieldKey) {
			
			jQuery.ajax({
				url:     cdcf_vars.url,
				type:    'POST',
				data:    {
					'action':    'cdcf_acf_field_selection',
					'menu':      menu,
					'post_ID':   cdcf_vars.post_id,
					'field_key': fieldKey,
					'day':       elem.attr('id')
				},
				success: function (data) {
					
					//jQuery('#poststuff').prepend(data);
					elem.next().find(jQuery('.cdcf_acf_dishes select')).empty().append(data);
					
				}
				
			});
			
		}
		
	}
	
});